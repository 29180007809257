import { keyframes } from '@emotion/react';
import styled from '@emotion/styled';

/* eslint-disable no-irregular-whitespace */
const dots = keyframes`
  0% {
    content: '.  ';
  }
  33% {
    content: '.. ';
  }
  66% {
    content: '...';
  }
`;
/* eslint-enable no-irregular-whitespace */

const rotate = keyframes`
  100% {
    transform: rotate(360deg);
  }
`;

const dash = keyframes`
  0% {
    stroke-dasharray: 1, 200;
    stroke-dashoffset: 0;
  }
  50% {
    stroke-dasharray: 89, 200;
    stroke-dashoffset: -35;
  }
  100% {
    stroke-dasharray: 89, 200;
    stroke-dashoffset: -124;
  }
`;

export const Loader = styled.div`
  svg {
    animation: ${rotate} 2s linear infinite;
    position: relative;

    circle {
      stroke-dashoffset: 0;
      stroke-linecap: round;
      stroke-dasharray: 1, 200;
      animation: ${dash} 1.5s ease-in-out infinite;
    }
  }
  .dots:after {
    content: '';
    width: 1rem;
    animation: ${dots} 1s linear infinite;
  }
  &.page-loader {
    top: 0;
    left: 0;
    z-index: 1;
  }
`;
