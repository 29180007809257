import { listenStorage, useDebounceCallback } from '@sortlist-frontend/utils';
import { useCallback, useEffect, useState } from 'react';

import { COMPARE_STORAGE_KEY, ComparedAgency, syncStorage } from '_components/Comparator/utils';

export const useComparedAgencies = () => {
  const [comparedAgencies, setComparedAgencies] = useState<ComparedAgency[]>([]);
  const syncCompared = () => syncStorage(setComparedAgencies);
  const debouncedSync = useCallback(useDebounceCallback(syncCompared, 300), []);

  useEffect(debouncedSync, []);

  listenStorage(COMPARE_STORAGE_KEY, debouncedSync);

  return {
    comparedAgencies,
    setComparedAgencies,
  };
};
